<template>
  <div>
    <div class="pr-3 pl-3" v-if="!!getErrorMessage">
      <v-alert
        :message="$t(`${getErrorMessage}`)"
        type="danger"
        id="rma-form-request-error"
      />
    </div>

    <b-form @submit="handleSubmit">
      <v-complaint-type-form
        :isRight="isRightLangDirection"
        :currentLang="getCurrentLang"
        :state="handleState('complaintType')"
        @onSelect="(value) => handleRadioChange('complaintType', value)"
      />

      <v-complaint-method-form
        :isRight="isRightLangDirection"
        :isRepairDisabled="complaintType === 'RETURN'"
        :state="handleState('complaintMethod')"
        @onSelect="(value) => handleRadioChange('complaintMethod', value)"
      />

      <v-add-product-form
        :isRight="isRightLangDirection"
        :isInvalid="isProductFormInvalid"
        :isDisabled="isDisabled"
        :returnReason="complaintMethod"
        :state="handleState('addProductFormType')"
        @onSelect="(value) => handleRadioChange('addProductFormType', value)"
      />

      <v-form-card
        v-show="getProducts.length"
        id="contact-data"
        :isRight="isRightLangDirection"
        :isRequired="true"
        :title="contactDataTitle"
      >
        <div class="row">
          <div class="col-lg-4 mb-3 mt-3 pl-lg-4 pr-lg-3">
            <v-contact-form
              :isRight="isRightLangDirection"
              :stateContactName="handleState('contactName')"
              :stateContactSurname="handleState('contactSurname')"
              :stateContactEmail="handleState('contactEmail')"
              :stateContactPhonePrefix="handleState('contactPhonePrefix')"
              :stateContactPhone="handleState('contactPhone')"
              @onChange="handleChange"
            >
              <template
                v-if="handleState('contactEmail') !== undefined"
                slot="contactEmailError"
              >
                <b-form-invalid-feedback
                  v-if="!$v.contactEmail.required"
                  class="d-block"
                  id="contact-email-feedback"
                >
                  {{ $t("error.required") }}
                </b-form-invalid-feedback>

                <b-form-invalid-feedback
                  v-else-if="!$v.contactEmail.email"
                  class="d-block"
                  id="contact-email-feedback"
                >
                  {{ $t("error.email") }}
                </b-form-invalid-feedback>
              </template>
            </v-contact-form>
          </div>

          <div class="col-lg-4 mb-3 mt-3 pl-lg-3 pr-lg-4">
            <v-pickup-address-form
              :isRight="isRightLangDirection"
              :countriesArr="countriesArr"
              :statePickupAddressStreet="handleState('pickupAddressStreet')"
              :statePickupAddressCity="handleState('pickupAddressCity')"
              :statePickupAddressPostalCode="
                handleState('pickupAddressPostalCode')
              "
              :statePickupAddressCountry="handleState('pickupAddressCountry')"
              @onChange="handleChange"
            >
              <template
                v-if="handleState('pickupAddressPostalCode') !== undefined"
                slot="pickupAddressPostalCodeError"
              >
                <b-form-invalid-feedback
                  v-if="!$v.pickupAddressPostalCode.required"
                  class="d-block"
                  id="pickup-adress-postal-code-number-feedback"
                >
                  {{ $t("error.required") }}
                </b-form-invalid-feedback>
              </template>
            </v-pickup-address-form>
          </div>

          <div class="col-lg-4 mb-3 mt-3 pl-lg-3 pr-lg-4">
            <v-return-address-form
              :isRight="isRightLangDirection"
              :countriesArr="countriesArr"
              :pickupAddressStreet="pickupAddressStreet"
              :pickupAddressCity="pickupAddressCity"
              :pickupAddressPostalCode="pickupAddressPostalCode"
              :pickupAddressCountry="pickupAddressCountry"
              :stateReturnAddressStreet="handleState('returnAddressStreet')"
              :stateReturnAddressCity="handleState('returnAddressCity')"
              :stateReturnAddressPostalCode="
                handleState('returnAddressPostalCode')
              "
              :stateReturnAddressCountry="handleState('returnAddressCountry')"
              @onChange="handleChange"
            >
              <template
                v-if="handleState('returnAddressPostalCode') !== undefined"
                slot="returnAddressPostalCodeError"
              >
                <b-form-invalid-feedback
                  v-if="!$v.returnAddressPostalCode.required"
                  class="d-block"
                  id="return-adress-postal-code-number-feedback"
                >
                  {{ $t("error.required") }}
                </b-form-invalid-feedback>
              </template>
            </v-return-address-form>
          </div>
        </div>
      </v-form-card>

      <v-bank-details-form
        v-show="getProducts.length"
        :isRight="isRightLangDirection"
        :stateAccountNumber="handleState('accountNumber')"
        :stateBankCodeSwift="handleState('bankCodeSwift')"
        :stateBranchCode="handleState('branchCode')"
        @onChange="handleChange"
      />

      <v-form-card :isRight="isRightLangDirection">
        <div
          :class="{
            'text-left': isRightLangDirection,
            'text-right': !isRightLangDirection,
          }"
        >
          <b-button variant="success" type="submit" :disabled="getLoading">
            <span v-show="!getLoading">
              {{ $t("label.rma.send") }}
            </span>
            <i class="fas fa-spinner fa-pulse m-0" v-show="getLoading" />
          </b-button>
        </div>
      </v-form-card>
    </b-form>
  </div>
</template>

<script>
import countries from "i18n-iso-countries";

import { mapActions, mapGetters } from "vuex";

import { required, email } from "vuelidate/lib/validators";

// import { postalCode } from "@/utils";

import VAlert from "@/components/VAlert";
import VFormCard from "@/components/VFormCard";
import VComplaintTypeForm from "./VComplaintTypeForm";
import VComplaintMethodForm from "./VComplaintMethodForm";
import VAddProductForm from "./VAddProductForm";
import VPickupAddressForm from "./VPickupAddressForm";
import VReturnAddressForm from "./VReturnAddressForm";
import VContactForm from "./VContactForm";
import VBankDetailsForm from "./VBankDetailsForm";

export default {
  name: "RmaForm",

  components: {
    VAlert,
    VFormCard,
    VComplaintTypeForm,
    VComplaintMethodForm,
    VAddProductForm,
    VPickupAddressForm,
    VReturnAddressForm,
    VContactForm,
    VBankDetailsForm,
  },

  data() {
    return {
      isProductFormInvalid: false,
      countriesList: [],
      complaintType: null,
      complaintMethod: null,
      addProductFormType: null,
      accountNumber: null,
      bankCodeSwift: null,
      branchCode: null,
      pickupAddressStreet: null,
      pickupAddressCity: null,
      pickupAddressPostalCode: null,
      pickupAddressCountry: null,
      returnAddressStreet: null,
      returnAddressCity: null,
      returnAddressPostalCode: null,
      returnAddressCountry: null,
      contactName: null,
      contactSurname: null,
      contactEmail: null,
      contactPhonePrefix: null,
      contactPhone: null,
    };
  },

  validations: {
    complaintType: {
      required,
    },
    complaintMethod: {
      required,
    },
    addProductFormType: {
      required,
    },
    pickupAddressStreet: {
      required,
    },
    pickupAddressCity: {
      required,
    },
    pickupAddressPostalCode: {
      required,
      // postalCode: postalCode("pickupAddressCountry"),
    },
    pickupAddressCountry: {
      required,
    },
    returnAddressStreet: {
      required,
    },
    returnAddressCity: {
      required,
    },
    returnAddressPostalCode: {
      required,
      // postalCode: postalCode("returnAddressCountry"),
    },
    returnAddressCountry: {
      required,
    },
    contactName: {
      required,
    },
    contactSurname: {
      required,
    },
    contactEmail: {
      required,
      email,
    },
    contactPhonePrefix: {
      required,
    },
    contactPhone: {
      required,
    },
    accountNumber: {
      required,
    },
    bankCodeSwift: {
      required,
    },
    branchCode: {
      required,
    },
  },

  watch: {
    getCurrentLang(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getCountriesList();
      }
    },
  },

  methods: {
    ...mapActions("rmaForm", ["sendRmaForm"]),

    getCountriesList() {
      const languages = this.getLanguages;

      if (languages) {
        for (let i = 0; i < languages.length; i++) {
          countries.registerLocale(
            require(`i18n-iso-countries/langs/${languages[i].langCode}.json`)
          );
        }

        this.countriesList = countries.getNames(this.getCurrentLang);

        try {
          localStorage.setItem("returnAddressCountry", this.getCurrentLang);
          localStorage.setItem("pickupAddressCountry", this.getCurrentLang);
        } catch (err) {
          console.error(err);
        }
      }
    },

    focusFirstState(component = this) {
      if (component.state === false) {
        component.$el.scrollIntoView({
          behavior: "smooth",
        });
        return true;
      }

      let focused = false;

      component.$children.some((childComponent) => {
        focused = this.focusFirstState(childComponent);
        return focused;
      });

      return focused;
    },

    saveCurrentCountry(name, value) {
      try {
        localStorage.setItem(
          name,
          Object.keys(this.countriesList).find(
            (key) => this.countriesList[key] === value
          ) || this.getCurrentLang
        );
      } catch (err) {
        console.error(err);
      }
    },

    handleState(name) {
      return !this.$v[name].$error && this.$v[name].$model === null
        ? undefined
        : !this.$v[name].$error;
    },

    handleRadioChange(name, value) {
      this[name] = value;
    },

    handleChange(name, value) {
      this[name] = value;

      if (name === "returnAddressCountry" || name === "pickupAddressCountry")
        this.saveCurrentCountry(name, value);

      if (this.$v[name]) this.$v[name].$touch();
    },

    handleSubmit(event) {
      event.preventDefault();

      const lines = this.getProducts;

      this.isProductFormInvalid = lines.length === 0;

      this.$v.$touch();

      this.$nextTick(() => this.focusFirstState());

      if (!this.$v.$invalid && !this.isProductFormInvalid) {
        this.sendRmaForm({
          lines,
          contact: {
            firstName: this.contactName || null,
            lastName: this.contactSurname || null,
            email: this.contactEmail || null,
            phone: this.contactPhone
              ? `${
                  this.contactPhonePrefix
                    ? `(+${this.contactPhonePrefix}) `
                    : null
                }${this.contactPhone}`
              : null,
          },
          returnAddress: {
            street: this.returnAddressStreet || null,
            city: this.returnAddressCity || null,
            postalCode: this.returnAddressPostalCode || null,
            country: this.returnAddressCountry || null,
          },
          bankDetails: {
            accountNumber: this.accountNumber || "",
            bankCodeSwift: this.bankCodeSwift || null,
            branchCode: this.branchCode || null,
          },
          additionalInformations: {
            complaintType: this.complaintType || null,
            preferredRealisation: this.complaintMethod || null,
            pickupAddress: {
              street: this.pickupAddressStreet || null,
              city: this.pickupAddressCity || null,
              postalCode: this.pickupAddressPostalCode || null,
              country: this.pickupAddressCountry || null,
            },
          },
        }).then(() => {
          if (this.getSuccessData) {
            this.$router.push({
              name: this.getActiveStep.name,
            });
          }
        });
      }
    },
  },

  computed: {
    ...mapGetters("app", [
      "getCurrentLang",
      "getErrorMessage",
      "isRightLangDirection",
    ]),
    ...mapGetters("steps", ["getActiveStep"]),
    ...mapGetters("translation", ["getLanguages"]),
    ...mapGetters("rmaForm", ["getLoading", "getSuccessData"]),
    ...mapGetters("products", ["getProducts"]),

    isDisabled() {
      return !!(!this.complaintType || !this.complaintMethod);
    },

    contactDataTitle() {
      return "4. " + this.$t("label.shipping-details.form.title");
    },

    countriesArr() {
      if (!this.getCurrentLang) return [];

      const arr = this.countriesList;
      const res = [];

      for (let key of Object.keys(arr)) {
        res.push(arr[key]);
      }

      return res;
    },
  },

  created() {
    this.getCountriesList();
  },
};
</script>
