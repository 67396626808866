<template>
  <v-form-card id="bank-details-form" :isRight="isRight" :title="title">
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="8">
        <h5 class="d-flex">
          <img
            class="img-icon d-flex"
            :class="{
              'mr-2': !isRight,
              'ml-2': isRight,
            }"
            src="@/assets/images/icon-payment.png"
            alt="img-payment"
          />

          <span class="card-small-title d-flex">{{
            $t("label.bank-details.form.title")
          }}</span>
        </h5>

        <p>{{ $t("label.bank-details.description") }}</p>

        <v-input
          :isRight="isRight"
          id="account-number"
          :isRequired="true"
          :label="$t('label.bank-details.account-number')"
          :state="stateAccountNumber"
          :error="$t('error.required')"
          @onChange="(val) => handleChange('accountNumber', val)"
        />

        <v-input
          :isRight="isRight"
          id="bank-code-swift"
          :isRequired="true"
          :label="$t('label.bank-details.bank-code-swift')"
          :state="stateBankCodeSwift"
          :error="$t('error.required')"
          @onChange="(val) => handleChange('bankCodeSwift', val)"
        />

        <v-input
          :isRight="isRight"
          id="branch-code"
          :isRequired="true"
          :label="$t('label.bank-details.branch-code')"
          :state="stateBranchCode"
          :error="$t('error.required')"
          @onChange="(val) => handleChange('branchCode', val)"
        />
      </b-col>
    </b-row>
  </v-form-card>
</template>

<script>
import VFormCard from "@/components/VFormCard";
import VInput from "@/components/VInput";

export default {
  name: "VBankDetailsForm",

  components: {
    VFormCard,
    VInput,
  },

  data() {
    return {
      accountNumber: null,
      bankCodeSwift: null,
      branchCode: null,
    };
  },

  props: {
    isRight: {
      type: Boolean,
      default: true,
    },
    stateAccountNumber: {
      type: Boolean,
      default: undefined,
    },
    stateBankCodeSwift: {
      type: Boolean,
      default: undefined,
    },
    stateBranchCode: {
      type: Boolean,
      default: undefined,
    },
  },

  methods: {
    handleChange(name, value) {
      this[name] = value;
      this.$emit("onChange", name, value);
    },
  },

  computed: {
    title() {
      return "5. " + this.$t("label.billing-details.form.title");
    },
  },
};
</script>
